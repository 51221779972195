#popcorn-machine{
    width: 40%;
    bottom: 55%;
    left: 10%;
    position: absolute;
}

@media (max-width: 768px) {
    #popcorn-machine{
        bottom: 50%;
    }
}

.right-banner{
    height: 300px;
    width: 429px;
    
}
@media (max-width: 1214px) {
    #popcorn-machine{
        bottom: 70%;
    }
}
@media (max-width: 990px) {
    #popcorn-machine{
        position: relative;
        margin-top: 15rem;
    }
}
@media (max-width: 470px) {
    .right-banner{
        height: 197.81px;
        width: 320px;
        
    }
}