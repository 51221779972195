.home-jumbo {
	margin-top: 1rem;
	display: flex;
	justify-content: center;
}
.steps {
	.step {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: start;
		svg {
			font-size: 2rem;
			opacity: 0.8;
			margin: 1rem;
		}
		h4 {
			font-size: 1rem;
			text-align: center;
			opacity: 0.8;
		}
	}
	.step-arrow {
		justify-content: center;
		display: none;
	}
	.step-text {
		text-align: center;
	}
}
.row-title {
	text-align: center;
	opacity: 0.9;
	h1 {
		font-size: 2rem;
	}
}

.funshine-why {
	margin-top: 4rem;
	padding-bottom: 4rem;
}

.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators {
	margin-bottom: 0 !important;
	li {
		background-color: black;
	}
}
#funshineCarousel {
	margin-top: 5rem;
	height: 15rem;
	width: 100%;
}
.jumbo-img {
	height: 10rem;
}
.carousel-control-next {
	right: -1.75rem;
}
.carousel-control-prev {
	left: -1.75rem;
}
