@import './_mixins';

.funshine-nav {
	@include brown-theme-bg;
	box-shadow: 2px 2px 2px rgb(0, 0, 0, 0.3);
	padding-left: 5%;
	padding-right: 5%;
	.funshine-nav-title {
		text-shadow: 1.5px 1.5px 1.5px rgb(0, 0, 0, 0.5);
		font-family: 'SparkPlug';
		font-size: 3rem ;
		@include green-theme;
		margin-bottom: 0 !important;
		padding-left: 5%;
	}
	.navbar-nav {
		justify-content: center;
		
	}
	.contact-button{
	 @include green-theme-bg;
	 width: 10rem;
	 height: 2rem;
	 @include pinkish-font;
	 text-shadow: 1.5px 1.5px 1.5px rgb(0, 0, 0, 0.5);
	 font-size: 1rem;
	 display: flex;
					align-items: center;
					justify-content: center;
	}
	.navbar-brand{
		display: flex;
		align-items: center;
	}
	#navbarNavbar{
		
		justify-content: flex-end;
	}
	.nav-link {
		
		@include pinkish-font;
		font-size: .9rem;
	}
	.nav-item{
		margin-left: .5rem;
		margin-right: .5rem;
		display: flex;
		align-items: center;
		text-shadow: 1.5px 1.5px 1.5px rgb(0, 0, 0, 0.5);
	}
	.logo-nav{
		height: 4rem;
	}
}
@media (max-width: 768px) {
	.navbar-collapse{
		position: absolute;
		top: 3.5rem;
		width: 100vw;
		left: 0;
		background-color: rgba(0, 0, 0, 0.61);
		padding: 1rem;
	}
}

@media (max-width: 654px) {
	.funshine-nav{
		padding-left: 5%;
		padding-right: 5.5%;
		.funshine-nav-title{
			font-size: 2rem;
		}
		.logo-nav{
			height: 3rem;
		}
	}
}
@media (max-width: 425px) {
	.funshine-nav{
		padding-left: 2.5%;
		padding-right: 2.5%;
		.logo-nav{
			display: none;
		}
	}
}

@media (max-width: 1006px) and (min-width: 768px){
	.funshine-nav{
		padding-left: 2.5%;
		padding-right: 2.5%;
		.btn{
			width: 7rem;
			font-size: .8rem;
			
		}
		.nav-link{
			font-size: .8rem;
			margin-left: .1rem;
			margin-right: .1rem;
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
		.funshine-nav-title{
			font-size: 2rem;
		}
	}
}