@import './_mixins';
.about-container{
    @include pink-theme-bg;
    text-align: center;
    height: 100vh;
    .about-wrapper-row{
        height: 100%;
    }
    .about-header-col{
        padding-top: 10%;
        width: 100%;
       
        h1{
            text-align: center;
        }
    }
    .about-body{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 30%;
        p{
            width: 50%;
            
        }
    }
}
@media (max-width: 768px){
    .about-body{
        height: 50%;
        width: 100%;
        margin: 0;
        p{
            width: 100% !important;
            
        }
    }
}