
@import './public_assets/styling/_mixins';
@import './public_assets/styling/home.scss';
@import './public_assets/styling/navigation.scss';
@import './public_assets/styling/itemcard.scss';
@import './public_assets/styling/footer.scss';
@import './public_assets/styling/rentals.scss';
@import './public_assets/styling/newHome.scss';
@import './public_assets/styling/images.scss';
@import './public_assets/styling/tbb.scss';
@import './public_assets/styling/about.scss';
@import './public_assets/styling/faq.scss';

button{
    &:hover{
        cursor: pointer;
        opacity: .8;
    }
}
a{
    &:hover{
        cursor: pointer;
        opacity: .8;
    }
}