.item-card-wrapper {
	display: inline-flex;
	border-radius: 20px;
	box-shadow: 2px 2px 5px rgb(0, 0, 0, 0.2);
	width: 16rem;
	
	height: 24rem;
	
	
	align-items: center;
	&:hover {
		cursor: pointer;
		opacity: 0.5;
	}
	img {
		overflow: hidden;
		width: 12rem !important;
		height: 10rem !important;
		min-width: 12rem !important;
		min-height: 10rem !important;
	}
	text-align: center;
	.item-name {
		font-size: 1.25rem;
	}
	.item-text-container {
		height: 10%;
		padding: 0;
	}
	.item-des{
	

	}
	.item-cost{
		padding: 0;
	}
	h5{
		font-size: .75rem;
	}
}
