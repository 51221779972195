@import './_mixins';
.funshine-footer {
	@include brown-theme-bg;
	box-shadow: 2px 2px 2px rgb(0, 0, 0, 0.3);
	position: relative;
	height: 9.2rem;
	bottom: 0;
	margin-right: -15px;
	margin-left: -15px;
	.icon{
		height: 1.2rem;
	}
	.facebook{
		margin-right: 1rem;
	}
	.insta{
		margin-right: 1rem;
	}
	.icons{
		position: absolute;
		top: 4rem;
		
	}
	.name-logo{
		height: 10rem;
		position: absolute;
		top: -1rem;
		width: 15rem;
		transform: rotate(-1deg);
	}
	p{
		margin: .3rem;
		width: 60px;
		text-align: center
	}
	h5, p{
		@include pinkish-font;
	}
	.footer-links{
		display: flex;
		position: absolute;
		bottom: 0;
		p{
			font-size: .75rem;
			@include green-theme;
			font-weight: 700;
		}
	}
	.footer-container{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;	
	}
	.btn{
		height: 2rem;
		padding: 0;
	}
	.call{
		svg{
			@include pinkish-font;
			font-size: 1.15rem;
		}
		width: 1rem;
		height: 1rem;
	}
}
