.tbb-row{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
   padding-top: 2.5rem;
   padding-bottom: 5rem;
}

.tbb-2{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    img{
        height: 10rem;
        margin-bottom: 0 !important;
    }
}

.tbb-1{
    margin-bottom: 5rem;
}

.tbb-0{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    h3{
        width: 50%;
    }
    img{
        height: 20rem ;
  
        margin-bottom: 5rem;
    }
}
@media (max-width: 500px) {
    .tbb-0{
        img{
            height: 10rem !important;
      
            margin-bottom: 5rem;
        }
        h3{
            font-size: 1rem;
        }
    }
    .tbb-1{
        h5{
            font-size: .75rem;
        }
    }
    .tbb-2{
        margin-bottom: 10rem;
        img{
            height: 10rem;
            
        }
        h4{
            font-size: 1.1rem;
        }
    }
}