
@mixin blue-theme {
	color: rgb(104,199,179) !important
}
@mixin brown-theme {
	color: rgb(55,30,24) !important
}
@mixin green-theme {
	color: rgb(79, 189, 132) !important
}
@mixin pink-theme {
	color: rgb(249,211,192) !important
}
@mixin blue-theme-bg {
	background-color: rgb(104,199,179) !important
}
@mixin brown-theme-bg {
	background-color: rgb(55,30,24) !important
}
@mixin green-theme-bg {
	background-color: rgb(79, 189, 132) !important
}
@mixin pink-theme-bg {
	background-color: rgb(249,211,192) !important
}
@mixin pinkish-font {
	color: rgb(252,235,226) !important
}
@font-face {
    font-family: "SparkPlug";
    src: local("SparkPlug"),
     url("../fonts/SparkPlug.ttf") format("truetype");
    }