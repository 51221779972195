@import './_mixins';
.rental-header-row {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2.5rem;
	img{
		width: 20rem;
		height: auto;
	}
	h1 {
		margin-top: 5rem;
		text-align: center;
	}
}
.rental-body-row {
	justify-content: center;
}
.itemcard-wrapper {
	margin-top: 1rem;
	margin-bottom: 1rem;
	margin-left: 1rem;
	margin-right: 1rem;
}
.vertical-rental-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	.rental-header-text{
		margin-top: 5rem;
		margin-bottom: 5rem;
		padding-left: 5%;
	}
	hr{
		height: 2px;
		width: 80%;
	}
	.rental-container {
		width: 100%;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
	.extras-container {
		width: 100%;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
	}
}
hr {
	border-top: 2px solid rgba(0, 0, 0, 0.3);
}
.machines{
	margin: 0 !important;
	width: 100%;
}
.extras{
	margin: 0 !important;
	width: 100%;
}
.rental-row-big{
	padding-bottom: 10rem;
}
.rental-disclaimers-row{
	display: flex;
	justify-content: center;
	padding-top: 10rem;
	text-align: center;
}
.rental-page{
@include pink-theme-bg;
p, h3, h4, h5{
	@include brown-theme;
	
}
h1,h2{
	@include blue-theme;
	text-shadow: 1.5px 1.5px 1.5px rgb(0, 0, 0, 0.5);
}
.itemcard-wrapper{
	@include blue-theme-bg;
}

}
.logo-rentals{
	height: 15rem;
}


@media (max-width: 1100px) {
	.rental-row-big{
		width: 100%;
		margin: 0;
	}
	.rental-center{
		padding-left: 5%;
		padding-right: 5%;
	}
	.rental-container{
		
	}
}
@media (max-width: 442px) {
	.rental-header-row{
		padding: 0%;
		img{
			width: 15rem;
		}
		h1{
			font-size: 2rem;
		}
	}
	.vertical-rental-container{
		padding: 0;
	}
}