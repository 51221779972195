@import './_mixins';
.faq-container{
    @include pink-theme-bg;
    text-align: start;
    height: 100%;
    padding-left: 20%;
    padding-right: 20%;
    .faq-wrapper-row{
        height: 100%;
    }
    .faq-header-col{
        padding-top: 10%;
        width: 100%;
        text-align: center !important;
        margin: 2rem;
    }
    .question{
        font-weight: bold;
    }
    .faq-body{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 30%;
        p{
            width: 90%;
            
        }
    }
}
@media (max-width: 768px){
    .faq-container{
        padding-left: 2%;
        padding-right: 2%;
    }
    .faq-body{
        height: 50%;
        width: 100%;
        margin: 0;
        p{
            width: 100% !important;
            
        }
    }
}