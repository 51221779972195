/**
 * @prettier
 */
@import './_mixins';

#newhome-container {
	.container-fluid {
		height: 100%;
	}
	.row {
		padding-left: 10%;
		padding-right: 10%;
	}
	.background {
		z-index: -2;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
}
.blue-strip {
	@include blue-theme-bg;
	position: absolute;
	left: 0;
	right: 0;
	bottom: -20%;
	height: 50%;
	z-index: -1;
	box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.15) inset,
		5px 30px 50px rgba(104, 199, 179, 0.75);
}

.pink-bg-div {
	@include pink-theme-bg;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	z-index: -10;
	box-shadow: 0px -10px 5px rgba(0, 0, 0, 0.2);
}
.intro-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	margin-top: 4rem;
	.btn {
		padding-bottom: 0.5rem;
		border-radius: 5rem;
		@include green-theme-bg;
		margin-top: 3rem;
		width: 15rem;
		height: 4rem;
		@include pinkish-font;
		text-shadow: 1.5px 1.5px 1.5px rgb(0, 0, 0, 0.5);
		font-size: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.steps {
	display: flex;
	flex-direction: column;

	align-items: center;
	padding-top: 5%;
}
.step-container {
	display: flex;
}
.section-title {
	h5 {
		font-size: 5rem;
		text-align: center;
	}
}
.first-row {
	height: 110vh;
}
.row-2 {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	.row {
		height: 40% !important;
	}
}
.main-right {
	display: flex;
	justify-content: center;
	padding-top: 5%;
}
@media (max-width: 990px) {
	.row-2 {
		padding-top: 30%;
	}
	.main-left {
		display: none;
	}
	.main-right {
		display: flex;
		justify-content: center;
	}
	.pink-bg-div {
		position: static;
		height: 80%;
	}
	.blue-strip {
		position: static;
	}
	#goals-anchor {
		text-align: center;
	}
}
@media (max-width: 700px) {
	.row-2 {
		padding-top: 50%;
	}
}
@media (max-width: 500px) {
	.row-2 {
		padding-top: 70%;
	}
}
.App {
	height: 100% !important;
}
html,
body {
	height: 100% !important;
}
